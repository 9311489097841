import Glide from "@glidejs/glide"
import $ from "jquery"

const landingInit = () => {

    const peekAmount = 50
    const gap = 15
    const phonePeekAmount = 20
    let glide = null

    const handleGlideEvents = () => {
        glide.on(['mount.after', 'run'], () => {
            if (glide.index === 0) {
                $('#landing__glider__prev__button__left__container').css('visibility', 'hidden')
            } else {
                $('#landing__glider__prev__button__left__container').css('visibility', 'visible')
            }
        })
    }

    const handleButtonClicks = () => {
        $('#landing__glider__prev__button__left').click(() => {
            glide.go('<')
        })

        $('#landing__glider__next__button__right').click(() => {
            glide.go('>')
        })
    }

    const calculateArrows = () => {
        const rightButton = $('#landing__glider__next__button__right__container')
        const leftButton = $('#landing__glider__prev__button__left__container')

        rightButton.css('right', `${peekAmount - rightButton.outerWidth() / 2 - gap / 2}px`)
        leftButton.css('left', `${peekAmount - leftButton.outerWidth() / 2 - gap / 2}px`)
    }

    const createGlider = () => {
        glide = new Glide('#landing__glide', {
            gap,
            peek: {before: 0, after: peekAmount},
            breakpoints: {
                1200: {
                    peek: {before: peekAmount, after: peekAmount},
                },

                600: {
                    gap: gap - 10,
                    peek: {before: phonePeekAmount, after: phonePeekAmount},
                }
            }
        }).mount()
    }

    const showLandingContainer = () => {
        $('#landing__container').css('opacity', '1')
    }

    $('#landing__first__image').on('load', showLandingContainer)

    setTimeout(showLandingContainer, 2000)

    createGlider()
    calculateArrows()
    handleGlideEvents()
    handleButtonClicks()
}

$(document).on('ready turbolinks:load', () => {
    if (location.pathname === "/") {
        landingInit()
    }
})


