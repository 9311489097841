import $ from 'jquery'

export const handleValidation = (valid, errorElement, message) => {
    $('#submit__button__error').text("")

    if (valid) {
        errorElement.text('')
        return true
    } else {
        errorElement.text(message)
        return false
    }
}