import "lightgallery.js"
import "lg-thumbnail.js"
import $ from 'jquery'

import Glide from "@glidejs/glide"

const galleryInit = () => {

    const gliders = []

    const createGalleyItems = () => {
        $('.gallery__id__query').each((index, element) => {
            lightGallery(document.getElementById(element.id), {
                download: false,
                thumbnail: true,
                hideBarsDelay: 1000,
            })
        })
    }

    const addGalleryEvents = () => {
        $('.gallery__arrow__container').click((e) => {
            const data = e.target.dataset
            gliders[data.index].go(data.direction)
        })
    }


    const initGlider = () => {
        $('.glide').each((index, element) => {
            const glider = new Glide(`#${element.id}`, {
                type: 'slider',
                perView: 3,
                breakpoints: {
                    1200: {
                        perView: 2,
                        peek: 40
                    },
                    900: {
                        perView: 2,
                        peek: 22
                    },
                    600: {
                        perView: 1,
                        peek: 22
                    }
                }
            })
            glider.mount()
            gliders.push(glider)
        })
    }

    createGalleyItems()
    initGlider()
    addGalleryEvents()

}

$(document).on('ready turbolinks:load', () => {
    if (location.pathname === "/gallery") {
        galleryInit()
    }
})
