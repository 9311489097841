import Glide from "@glidejs/glide"
import $ from 'jquery'

const homeTestimonialsInit = () => {

    let glide = null

    const createGlider = () => {
        glide = new Glide('#testimonials__glide')
        glide.on('build.after', updateSlideHeight)
        glide.on('run.after', updateSlideHeight)
        glide.mount()
    }

    const updateSlideHeight = () => {
        const targetHeight = $('#testimonials__glide .glide__slide--active .home__testimonials__container').height()
        $('#testimonials__glide .glide__slides').height(targetHeight)
    }

    const addEvents = () => {
        $('#testimonial__prev__arrow').click(() => {
            glide.go('<')
        })

        $('#testimonial__next__arrow').click(() => {
            glide.go('>')
        })
    }

    createGlider()
    addEvents()
}

$(document).on('ready turbolinks:load', () => {
    if (location.pathname === "/") {
        homeTestimonialsInit()
    }
})