import $ from 'jquery'

const initTeamMemberFade = () => {

    $('.team__image').on('mouseenter mouseleave', (e) => {
        const target = $(e.target)
        if (e.type === 'mouseenter') {
            target.css('overflowY', 'auto')
            target.find('.team__image__overlay, .team__text__bio').fadeIn(300)
            if (target.find('.team__text__bio').outerHeight() > target.outerHeight()) {
                target.find('.team__image__overlay').height(target.find('.team__text__bio').outerHeight())
            }
        } else {
            target.css('overflowY', 'hidden')
            $('.team__text__bio, .team__image__overlay').fadeOut(300)
        }
    })
}

$(document).on('ready turbolinks:load', () => {
    initTeamMemberFade()
})