import $ from 'jquery'
import Typewriter from "typewriter-effect/dist/core";
import {getCsrfCode} from "../../utils/submit_contact";

const enterCodeInit = () => {

    const codeInput = $('#code__input')
    const codePageContainerSelector = $('#code__page__container')

    let welcomeMessage = null
    let eventId = null
    let eventDetails = null
    let helpMessage = null
    let hostDetails = null
    let typewriter = null
    let option = null
    let currentInputValue = null
    let token = null
    let scrollToBottomInterval = null


    const codeQuery = `Do you have an access code for the event? <br>y=yes<br>n=no<br>`
    const enterCode = `<br>Please enter your access code.<br>`
    const checkCode = `<br>Ok hold tight. Let me check your code.<br>`
    const errorMessage = `<br>Something went wrong. Please try again later.<br>`

    const codeCorrect = `<br>Your code looks good! Loading the full event page in<br><br>3<br>`
    const noCode = `<br>No worries. To get a code you just need to send us some information. Please fill out the info in the popup.<br><br>`
    const badInput = `<br>I'm sorry I didn't get that. Please type one of the options above.<br>`
    const wrongCode = `<br>The code entered was incorrect. Please try again and make sure to enter it correctly.<br>`

    let keys = []

    const textInit = () => {
        welcomeMessage = `Welcome to ${$('#code__event__name').text()}.<br><br>`
        eventId = $('#code__event__id').text()
        eventDetails = `<br>${$('#code__event__description').text()}<br>`
        helpMessage = `<br>${$('#code__event__help').text()}<br>`
        hostDetails = `<br>${$('#code__event__host__details').text()}<br>`
    }

    const stopScrollToBottom = () => {
        clearInterval(scrollToBottomInterval)
    }

    const startScrollToBottom = () => {
        const pageContainer = $('#code__page__container')
        scrollToBottomInterval = setInterval(() => {
            if (pageContainer.scrollTop() + pageContainer.outerHeight() + 40 > document.getElementById("code__page__container").scrollHeight) {
                pageContainer.scrollTop(pageContainer.scrollTop() + pageContainer.height())
            }
        }, 300)
    }

    const buttonInit = () => {
        if (navigator.platform.includes('Mac')) {
            $('.code__command').each((index, element) => {
                $(element).text($(element).text().replace('Alt', 'Option'))
            })
        }
    }

    const initType = () => {
        typewriter = new Typewriter('#code__type', {
            wrapperClassName: "code__text",
            delay: parseInt(0),
        })
    }

    const typeWelcomeMessage = () => {
        typewriter.typeString(welcomeMessage).start()
    }

    const typeCodeQuery = () => {
        startScrollToBottom()
        typewriter
            .typeString(codeQuery)
            .callFunction(turnOnInput, null)
            .callFunction(stopScrollToBottom, null)
            .start()
        option = 'doesUserHaveCode'
    }

    const turnOffInput = () => {
        codeInput.val('')
        codeInput.off('keyup', handleKeyPress)
        codeInput.css('display', 'none')
    }

    const typeEnterCode = () => {
        startScrollToBottom()
        typewriter
            .typeString(enterCode)
            .callFunction(turnOnInput, null)
            .callFunction(stopScrollToBottom, null)
            .start()
        option = 'enterCode'
    }

    const handleInput = () => {
        if (option === 'doesUserHaveCode') {
            if (currentInputValue === 'y' || currentInputValue === 'yes') {
                typeEnterCode()
            } else if (currentInputValue === 'n' || currentInputValue === 'no') {
                typeNoCode()
            } else {
                codePageContainerSelector.scrollTop(codePageContainerSelector.prop('scrollHeight'))
                typewriter.typeString(badInput).callFunction(turnOnInput, null).start()
            }
        } else if (option === 'enterCode') {
            typewriter.typeString(checkCode).start()
            postCode()
        }
    }

    const postCode = async () => {
        try {
            const res = await $.post({
                url: '/events-code',
                data: {access_code: currentInputValue, event_id: eventId},
                headers: getCsrfCode()
            })

            if (res.success) {
                typeCorrectCode()
                token = res.token

            } else
                typewriter.typeString(wrongCode).callFunction(turnOnInput, null).start()

        } catch (e) {
            typewriter.typeString(errorMessage).start()
        }
    }

    const typeCorrectCode = () => {
        turnOffInput()
        typewriter.typeString(codeCorrect)
            .pauseFor(500).typeString("2 <br>")
            .pauseFor(500).typeString("1 <br>")
            .pauseFor(500).callFunction(() => {
            location.href = `/events/${eventId}/details?token=${token}&event_id=${eventId}`
        }, null).start()
        option = 'correctCode'
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            currentInputValue = codeInput.val()
            turnOffInput()
            handleInput()
        }
    }

    const turnOnInput = () => {
        if (!codeInput) return
        codeInput.css('display', 'block')
        codeInput.focus()
        codeInput.on('keyup', handleKeyPress)
        $('#code__page__container').click(() => codeInput.focus())
    }

    const resetKeyType = key => {
        if (key === 'Å') {
            return "A"
        } else if (key === 'Í') {
            return "S"
        } else if (key === 'Î') {
            return "D"
        } else if (key === 'Ï') {
            return "F"
        } else if (key === '˝') {
            return "G"
        } else if (key === 'Ó') {
            return "H"
        } else {
            return key
        }
    }


    const typeNoCode = () => {
        turnOffInput()
        startScrollToBottom()
        typewriter.typeString(noCode).pauseFor(500)
            .typeString("Ready<br><br>").pauseFor(500)
            .typeString("Set<br><br>").pauseFor(500)
            .typeString("Go<br><br>").pauseFor(500)
            .callFunction(turnOffInput, null)
            .callFunction(openModal, null)
            .callFunction(stopScrollToBottom, null)
            .start()
    }

    const typeStaticMessage = message => {
        turnOffInput()
        startScrollToBottom()
        typewriter.typeString(message).callFunction(stopScrollToBottom, null).start()
    }


    const handleOptionKeyDown = e => {

        keys.push(resetKeyType(e.key).toLowerCase())

        if (keys.includes("alt") && keys.includes("shift")) {
            e.preventDefault()

            if (keys.includes("a")) {
                typeEnterCode()

            } else if (keys.includes("s")) {
                typeNoCode()

            } else if (keys.includes("d")) {
                typeStaticMessage(hostDetails)

            } else if (keys.includes("f")) {
                typeStaticMessage(eventDetails)

            } else if (keys.includes("g")) {
                clearPage()

            } else if (keys.includes("h")) {
                typeStaticMessage(helpMessage)
            }
        }
    }

    const clearPage = () => {
        turnOffInput()
        typewriter.deleteAll(1).start()
    }

    const addMobileEventListeners = () => {
        $('#code__enter__access__code').click(typeEnterCode)
        $('#code__request__access__code').click(typeNoCode)
        $('#code__show__host__details').click(() => typeStaticMessage(hostDetails))
        $('#code__show__event__details').click(() => typeStaticMessage(eventDetails))
        $('#code__clear__page').click(clearPage)
        $('#code__show__help').click(() => typeStaticMessage(helpMessage))
    }

    const addOptionsListener = () => {
        const query = window.matchMedia('(min-width: 1200px)');

        if (query.matches) {
            $(document).on('keydown', handleOptionKeyDown)
            $(document).on('keyup', () => {
                keys = []
            })
        } else {
            addMobileEventListeners()
        }
    }

    const initModal = () => {
        $('#code__modal__outer__container').click((e) => {
            if (e.target.id === 'code__modal__outer__container') {
                closeModal()
            }
        })
        $('#close__modal').click(closeModal)
    }

    const openModal = () => {
        $('#modal__container').fadeIn(300)
    }

    const closeModal = () => {
        $('#modal__container').fadeOut(300)
    }

    const containerInit = () => {
        const height = `calc(100vh - ${$('#code__options').outerHeight()}px)`;
        $('#code__page__container').css({'height': height, 'max-height': height})
    }

    containerInit()
    buttonInit()
    textInit()
    initType()
    typeWelcomeMessage()
    typeCodeQuery()
    addOptionsListener()
    initModal()
}

$(document).on('turbolinks:load', () => {
    if (window.location.pathname.includes("/code")) {
        enterCodeInit()
    }
})