import $ from "jquery";

const initFooter = () => {

    const updateFooterHeight = () => {
        $('#footer__spacer').height($('#footer').outerHeight())
    }

    $(window).resize(updateFooterHeight)
    updateFooterHeight()
}

$(document).on('turbolinks:load', () => {
    initFooter()
})