import $ from 'jquery'
import buildUrl from 'build-url'

const initBlogEntry = () => {

    const twitterShare = () => {
        const twitterUrl = buildUrl('https://twitter.com', {
            path: 'intent/tweet',
            queryParams: {
                text: $('#blog__entry__title').text(),
                url: window.location.href,
                via: $('#twitter__username').text(),
                hashtags: $('#hash__tags').text().replace(/[\#\s]/g, "")
            }
        });

        $('#twitter__share').attr('href', twitterUrl)
    }

    const initFacebookShare = async  () => {
        window.fbAsyncInit = () => {
            FB.init({
                appId            : "260682438283003",
                autoLogAppEvents : true,
                xfbml            : true,
                version          : 'v6.0'
            });
        }

        eval(await $.get('https://connect.facebook.net/en_US/sdk.js'))
    }

    const handleFacebookClick = () => {
        $('#facebook__share').click(() => {
            FB.ui({
                method: 'share',
                href: window.location.href,
                hashtag: $('#hash__tags').text(),
                quote: $('#blog__entry__title').text() + '|' + $('#blog__entry__preview__text').text()
            });
        })
    }

    twitterShare()
    initFacebookShare()
    handleFacebookClick()
}

$(document).on('ready turbolinks:load', () => {
    if (window.location.pathname.includes('/blog/')) {
        initBlogEntry()
    }
})