import {submitForm} from "../../utils/submit_contact"
import validator from "validator"
import $ from 'jquery'
import {inputEvents} from "../../utils/constants"
import {handleValidation} from "../../utils/validate_input"

const contactInit = () => {

    const addValidatorEvents = () => {
        $('#contact__name__input').on(inputEvents, (e) => {
            handleValidation(!validator.isEmpty(e.target.value), $('#contact__name__error'), "Name cannot be blank")
        })

        $('#contact__email__input').on(inputEvents, (e) => {
            handleValidation(validator.isEmail(e.target.value), $('#contact__email__error'), "Please enter a valid email address")
        })

        $('#contact__message__input').on(inputEvents, (e) => {
            handleValidation(!validator.isEmpty(e.target.value), $('#contact__message__error'), "Message cannot blank")
        })
    }

    const getPostValues = () => ({
        name: $('#contact__name__input').val(),
        email: $('#contact__email__input').val(),
        message: $('#contact__message__input').val(),
        enquiry: $('#contact__option__input').val()
    })

    const validInput = () => {
        const inputs = getPostValues()
        return handleValidation(!validator.isEmpty(inputs.name), $('#contact__name__error'), "Name cannot be blank") &&
        handleValidation(validator.isEmail(inputs.email), $('#contact__email__error'), "Please enter a valid email address") &&
        handleValidation(!validator.isEmpty(inputs.message), $('#contact__message__error'), "Message cannot blank")
    }

    const addContactSubmitEvent = () => {
        $('#contact__form').submit((e) => {
            e.preventDefault()
            if (validInput()) {
                submitForm(getPostValues(), '/contact', $('#contact__form'))
            } else {
                $('#submit__button__error').text("Please correct the errors above and try again")
            }
        })
    }

    addValidatorEvents()
    addContactSubmitEvent()
}

$(document).on('turbolinks:load', () => {
    if (window.location.pathname === "/contact") {
        contactInit()
    }
})
