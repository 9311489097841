window.initWebsiteCaptcha = () => {
    document.grecaptchaHandle = grecaptcha.render('grecaptcha-badge', {
        'sitekey': '6Lf_w9kUAAAAAADhqVVhlzlaKDY-iZRHG9iiTOKD',
        'badge': 'inline',
        'size': 'invisible'
    });
}

export const getCaptchaToken = () => {
    return new Promise(function (resolve) {
        grecaptcha.ready(() => {
            grecaptcha.execute(document.grecaptchaHandle).then((token) => {
                resolve(token)
            })
        })
    })
}