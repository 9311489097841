import {submitForm} from "../../utils/submit_contact"
import validator from "validator"
import $ from 'jquery'
import {inputEvents} from "../../utils/constants"
import {handleValidation} from "../../utils/validate_input"

const newsletterInit = () => {

    const addValidatorEvents = () => {
        $('#newsletter__name__input').on(inputEvents, (e) => {
            handleValidation(!validator.isEmpty(e.target.value), $('#newsletter__name__error'), "Name cannot be blank")
        })

        $('#newsletter__email__input').on(inputEvents, (e) => {
            handleValidation(validator.isEmail(e.target.value), $('#newsletter__email__error'), "Please enter a valid email address")
        })
    }

    const getPostValues = () => ({
        name: $('#newsletter__name__input').val(),
        email: $('#newsletter__email__input').val(),
    })

    const validInput = () => {
        const inputs = getPostValues()
        return handleValidation(!validator.isEmpty(inputs.name), $('#newsletter__name__error'), "Name cannot be blank") &&
            handleValidation(validator.isEmail(inputs.email), $('#newsletter__email__error'), "Please enter a valid email address")
    }

    const addContactSubmitEvent = () => {
        $('#newsletter__form').submit((e) => {
            e.preventDefault()
            if (validInput()) {
                submitForm(getPostValues(), '/newsletter', $('#newsletter__form'), 'newsletter')
            }
        })
    }

    addValidatorEvents()
    addContactSubmitEvent()
}

$(document).on('ready turbolinks:load', () => {
    if (window.location.pathname === "/") {
        newsletterInit()
    }
})