import $ from 'jquery'

const blogInit = () => {
    const addHighlightToBlogs = () => {
        $('.blog__article__container, #featured__blog').on("mouseenter mouseleave", (e) => {
            if (e.type === 'mouseenter') {
                if (e.currentTarget.id === "featured__blog") {
                    $('#featured__blog__heading').addClass("blog__title__active")
                } else {
                    $(e.currentTarget).find('.blog__title').addClass("blog__title__active")
                }

            } else {
                if (e.currentTarget.id === "featured__blog") {
                    $('#featured__blog__heading').removeClass("blog__title__active")
                } else {
                    $(e.currentTarget).find('.blog__title').removeClass("blog__title__active")
                }
            }
        })
    }



    addHighlightToBlogs()
}

document.addEventListener("turbolinks:load", () => {

    if (window.location.pathname === "/blog") {
        blogInit()
    }
})