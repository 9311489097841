import $ from 'jquery'
import {disablePageScroll, enablePageScroll} from 'scroll-lock';

const navInit = () => {

    const toggleMobileNav = () => {

        const mobileNavMenu = $('#mobile__nav__menu')

        mobileNavMenu.toggleClass('animate')
        if (mobileNavMenu.hasClass('animate')) {
            disablePageScroll()
            $('#mobile__nav__menu__parent').css('transform', 'translate(-10px, 10px)')
            $('#mobile__nav__open__container').fadeIn(300)
            $('#nav__logo').fadeOut(300)
        } else {
            enablePageScroll()
            $('#mobile__nav__open__container').fadeOut(300)
            $('#nav__logo').fadeIn(300)
            $('#mobile__nav__menu__parent').css('transform', 'initial')
        }
    }

    $('#mobile__nav__menu__parent').click(toggleMobileNav)
}

$(document).on('turbolinks:load', () => {
    if (!window.location.pathname.includes('/code') && !window.location.pathname.match(/events\/.*\/revealed/)) {
        navInit()
    }
});

$(document).on('turbolinks:click', () => {
    enablePageScroll()
})

