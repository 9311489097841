import {getCaptchaToken} from './capthca.js.erb'
import $ from 'jquery'
import nestedProperty from 'nested-property'
import {isApplyModal} from "../pages/apply/apply";

export const getCsrfCode = () => (
    {"X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')}
)

export const submitForm = async (data, url, formElement, type = "contact") => {

    try {
        $('#loading__wrapper').css('display', 'block')

        await $.post({
            url,
            data: {...data, captcha_token: await getCaptchaToken()},
            headers: getCsrfCode(),
        })

        $('#submit__success').css('display', 'block')

        if (isApplyModal()) {
            $('#code__modal__inner__container').css('height', 'auto')
        }

    } catch (error) {
        if (nestedProperty.get(error, 'responseJSON.error')) {
            $('#submit__failure__heading').text('Error')
            $('#submit__failure__paragraph').text(error.responseJSON.error)
        }

        $('#submit__failure').css('display', 'block')
    } finally {
        $('#loading__wrapper').css('display', 'none')
        formElement.css('display', 'none')
    }
}

