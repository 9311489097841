import {submitForm} from "../../utils/submit_contact"
import validator from "validator"
import $ from 'jquery'
import {inputEvents} from "../../utils/constants"
import {handleValidation} from "../../utils/validate_input"

export const isApplyModal = () => window.location.pathname.match(/\/events\/.*\/code/)

const applyInit = () => {

    const addValidatorEvents = () => {
        $('#apply__applicant__input').on(inputEvents, (e) => {
            handleValidation(!validator.isEmpty(e.target.value), $('#apply__applicant__error'), "Applicant cannot be blank")
        })

        $('#apply__email__input').on(inputEvents, (e) => {
            handleValidation(validator.isEmail(e.target.value), $('#apply__email__error'), "Please enter a valid email address")
        })

        $('#apply__city__town__input').on(inputEvents, (e) => {
            handleValidation(!validator.isEmpty(e.target.value), $('#apply__city__town__error'), "City/Town cannot be blank")
        })

        $('#apply__bio__input').on(inputEvents, (e) => {
            handleValidation(!validator.isEmpty(e.target.value), $('#apply__bio__error'), "Bio cannot be blank")
        })
    }


    const getPostValues = () => ({
        applicant: $('#apply__applicant__input').val(),
        email: $('#apply__email__input').val(),
        city: $('#apply__city__town__input').val(),
        referred_by: $('#apply__referred__by__input').val(),
        bio: $('#apply__bio__input').val(),
        event_id: $('#apply__event__input').val(),
        filled_out_for_someone_else: $('#apply__filled__out__for__someone__else__input').prop('checked'),
        know_the_person_filled_out_for: $('#apply__know__the__person__filled__out__for__input').prop('checked'),
        recommenders_name: $('#apply__recommenders__name__input').val()
    })

    const validInput = () => {

        const postValues = getPostValues()

        return handleValidation(!validator.isEmpty(postValues.applicant), $('#apply__applicant__error'), "Applicant cannot be blank") &&
            handleValidation(validator.isEmail(postValues.email), $('#apply__email__error'), "Please enter a valid email address") &&
            handleValidation(!validator.isEmpty(postValues.city), $('#apply__city__town__error'), "City/Town cannot be blank") &&
            handleValidation(!validator.isEmpty(postValues.bio), $('#apply__bio__error'), "Bio cannot be blank")
    }

    const addContactSubmitEvent = () => {

        const formId = window.location.pathname === "/apply" ? '#apply__form' : '#apply__modal__form'

        $(formId).submit(e => {
            e.preventDefault()
            if (validInput()) {
                submitForm(getPostValues(), '/apply', $(formId))
            } else {
                $('#submit__button__error').text("Please correct the errors above and try again")
            }
        })
    }

    const handleRecommendedDetails = () => {
        const input = $('#apply__filled__out__for__someone__else__input')
        input.change(() => {
            const recDetails = $('.apply__recommended__details')
            input.prop('checked') ?
                recDetails.css('display', 'block') :
                recDetails.css('display', 'none')
        })
    }

    addValidatorEvents()
    handleRecommendedDetails()
    addContactSubmitEvent()
}

$(document).on('ready turbolinks:load', () => {
    if (window.location.pathname === "/apply" || isApplyModal()) {
        applyInit()
    }
})