import {submitForm} from "../../utils/submit_contact"
import validator from "validator"
import $ from 'jquery'
import {inputEvents} from "../../utils/constants"
import {handleValidation} from "../../utils/validate_input"
import anime from "animejs";

const eventRevealedInit = () => {

    const addValidatorEvents = () => {
        $('#event__revealed__name__input').on(inputEvents, (e) => {
            handleValidation(!validator.isEmpty(e.target.value), $('#event__revealed__name__error'), "Name cannot be blank")
        })

        $('#event__revealed__email__input').on(inputEvents, (e) => {
            handleValidation(validator.isEmail(e.target.value), $('#event__revealed__email__error'), "Please enter a valid email address")
        })

        $('#event__revealed__phone__input').on(inputEvents, (e) => {
            handleValidation(validator.isMobilePhone(e.target.value), $('#event__revealed__phone__error'), "Please enter a valid phone number")
        })

        $('#event__revealed__facebook__input').on(inputEvents, (e) => {
            handleValidation(validator.isURL(e.target.value), $('#event__revealed__facebook__error'), "Please enter a valid URL")
        })

        $('#event__revealed__pseudonym__input').on(inputEvents, (e) => {
            handleValidation(!validator.isEmpty(e.target.value), $('#event__revealed__pseudonym__error'), "Pseudonym cannot be blank")
        })
    }

    const getPostValues = () => ({
        name: $('#event__revealed__name__input').val(),
        pseudonym: $('#event__revealed__pseudonym__input').val(),
        dietary_restrictions: $('#event__revealed__dietary__restrictions__input').val(),
        allergic_to_pets: $('#event__revealed__allergic__to__pets__input').val(),
        facebook_url: $('#event__revealed__facebook__input').val(),
        email: $('#event__revealed__email__input').val(),
        phone: $('#event__revealed__phone__input').val(),
        id: $('#event__revealed__id').val()
    })

    const validInput = () => {
        const postValues = getPostValues()
        return handleValidation(!validator.isEmpty(postValues.name), $('#event__revealed__name__error'), "Name cannot be blank") &&
            handleValidation(!validator.isEmpty(postValues.pseudonym), $('#event__revealed__pseudonym__error'), "Pseudonym cannot be blank") &&
            handleValidation(validator.isEmail(postValues.email), $('#event__revealed__email__error'), "Please enter a valid email address") &&
            handleValidation(validator.isMobilePhone(postValues.phone), $('#event__revealed__phone__error'), "Please enter a valid phone number") &&
            handleValidation(validator.isURL(postValues.facebook_url), $('#event__revealed__facebook__error'), "Please enter a valid URL") &&
            handleValidation($('#event__revealed__checkbox__input').prop("checked"), $('#event__revealed__checkbox__error'), "Please confirm that you are attending")
    }

    const addContactSubmitEvent = () => {
        $('#event__revealed__attend__form').submit(e => {
            e.preventDefault()
            if (validInput()) {
                submitForm(getPostValues(), '/events/attendee', $('#event__revealed__attend__form'))
            } else {
                $('#submit__button__error').text('Please correct the errors above')
            }
        })
    }


    const handleNavClick = () => {

        const navItems = $('.event__revealed__nav__item')
        const eventRevealedContainer = $('#event__revealed__contents__container')

        navItems.click(e => {
            navItems.css('font-family', 'Paragraph')
            const targetSectionId = $(e.target).attr('data-related-section')

            anime.timeline({
                easing: 'linear',
                duration: 0,
            }).add({
                targets: '.event__revealed__section__hidden',
                opacity: 0,
                duration: 300,
                complete: () => {
                    eventRevealedContainer.height(eventRevealedContainer.outerHeight())
                    $('.event__revealed__section__hidden').css('display', 'none')
                    $(e.target).css('font-family', 'Medium')
                }
            }).add({
                targets: `#${targetSectionId}`,
                opacity: 1,
                duration: 300,
                begin: () => {
                    eventRevealedContainer.height('auto')
                    $(`#${targetSectionId}`).css('display', 'block')
                }
            }, '+=200')
        })
    }

    addValidatorEvents()
    handleNavClick()
    addContactSubmitEvent()
}

$(document).on('turbolinks:load', () => {
    if (window.location.pathname.match(/^\/events\/.*\/revealed$/) || window.location.pathname.match(/^\/events\/.*\/details$/)) {
        eventRevealedInit()
    }
})